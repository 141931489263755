//Grab our vars
@import 'vars';

/* ==========================================================================
	 Base (Dark) Theme Styles
	 ========================================================================== */
.envirabox-theme-#{$theme_name} {
	border-radius: 2px;
	padding: 4px !important;
	background: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	/* Thumbs */
	.envirabox-thumbs {
		display: none;
		position: absolute;
		top: auto;
		width: auto;
		bottom: 10px;
		left: 0;
		right: 0;
		background: transparent;
		-webkit-tap-highlight-color: transparent;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		box-sizing: border-box;
		z-index: 99995;
		& > ul {
			list-style: none;
			position: absolute;
			position: relative;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			overflow-x: hidden;
			overflow-y: auto;
			font-size: 0;
			white-space: nowrap;
			& > li {
				float: left;
				overflow: hidden;
				padding: 0;
				margin: 2px;
				position: relative;
				box-sizing: border-box;
				display: inline-block;
				cursor: pointer;
				outline: none;
				-webkit-tap-highlight-color: transparent;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				margin: 0px 8px;
				&.envirabox-thumbs-active {
					opacity: 1;
					&.focused img {
						border: 1px solid #000099;
					}
				}
				& > img {
					top: 0;
					left: 0;
					max-width: none;
					max-height: none;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
			}
		}
	}

	/**
	 * Actions
	 */
	.envirabox-toolbar {
		position: fixed;
		right: 10px;
		z-index: 99999;
		height: 16px;

		& > div {
			display: inline-block;
			margin-left: 9px;
			width: 16px;
			height: 16px;
			text-indent: -9999px;
			position: relative;
			vertical-align: middle;
			&:before {
				font-size: 16px;
				text-indent: 0;
				position: absolute;
				top: 0;
				right: 0;
			}

			& a {
				display: block;
				width: 16px;
				height: 16px;
				position: relative;
				z-index: 1;
				background: none;
			}
		}

		& a {
			outline: none;
		}
		& .envira-thumbs-button:before {
			content: '\e801';
		}
		& .envira-close-button:before {
			content: '\e903';
		}
		& .envira-download-button:before {
			content: '\e910';
		}
		& .envira-printing-button:before {
			content: '\e911';
		}
		& .envira-fullscreen-button:before {
			content: '\e906';
		}
		& .envirabox-slideshow-button {
			&:before {
				content: '\e90b';
			}
			&.envirabox-button--pause:before {
				content: '\e909';
			}
		}
		& .envira-zoom-button {
			&:before {
				content: '\e90f';
			}
			& a:before {
				content: '';
			}
			&.zoom-on:before {
				content: '\e90e';
			}
		}
		& .envirabox-audio-button:before {
			content: ' ';
			width: 16px;
			height: 16px;
			background: url('../../../../assets/css/images/icons/audio-on.svg');
		}
		& .envirabox-audio-button {
			&.audio-started:before {
				content: ' ';
				background: url('../../../../assets/css/images/icons/audio-on.svg');
			}
			&.audio-stopped:before {
				content: ' ';
				background: url('../../../../assets/css/images/icons/audio-off.svg');
			}
		}

		& .envira-fullscreen-button {
			cursor: pointer;
		}
	}
	&.envira-supersize .envirabox-outer {
		background-color: rgba(0, 0, 0, 0.85);
		background-image: none;
		overflow: hidden;
		overflow-y: hidden;
	}
	.envira-social-buttons-exterior {
		opacity: 0;
		transition: opacity 300ms linear;
		-moz-transition: opacity 300ms linear;
		-webkit-transition: opacity 300ms linear;
		&.social-active {
			opacity: 1;
		}
	}
	/**
	 * Navigation
	 */
	.envirabox-navigation {
		li {
			a {
				width: 35px;
				height: 70px;
				background-color: rgba(0, 0, 0, 0.25);
				&:hover {
					background-color: rgba(0, 0, 0, 0.35);
				}
				span {
					background-image: none;
					visibility: visible;
					width: 35px;
					height: 70px;
					&:before {
						font-size: 16px;
					}
				}
			}
		}
		/**
		 * Previous Navigation
		 */
		.envirabox-arrow--left {
			border-radius: 0 5px 5px 0;
			color: transparent;
			span {
				left: 0px;
				visibility: visible;
				&:before {
					content: '\e90c';
					text-align: left;
					padding: 0px 7px;
					line-height: 70px;
				}
			}
		}

		/**
		 * Next Navigation
		 */
		.envirabox-arrow--right {
			border-radius: 5px 0 0 5px;
			color: transparent;
			span {
				right: 0px;
				visibility: visible;
				&:before {
					content: '\e908';
					text-align: right;
					padding: 0px 7px;
					line-height: 70px;
				}
			}
		}
	}

	.envirabox-thumbs {
		width: auto;
		bottom: 10px;
		left: 0;
		right: 0;
		overflow: hidden;
		background: transparent;
		& > ul {
			overflow: hidden;
		}
		ul li a {
			border: none;
		}
		ul li {
			margin: 0 10px;
		}
		ul li.active {
			opacity: 1;
		}
		&.bottom {
			bottom: 0;
		}
		& ul.mobile li {
			margin: 10px 5px;
		}
	}

	.envirabox-thumbs > ul > li {
		border-color: transparent;
	}

	.envirabox-container--thumbs .envirabox-caption-wrap,
	.envirabox-container--thumbs .envirabox-controls,
	.envirabox-container--thumbs .envirabox-slider-wrap {
		right: 0;
		top: 10px;
	}
	/* Caption */
	.envirabox-caption-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		padding: 0;
		background: rgba(0, 0, 0, 0.25);
		// pointer-events: none;
		min-height: 36px;
		line-height: 36px;
		& > div {
			display: inline-block;
		}
	}

	.envirabox-caption {
		padding: 5px 0;
		margin-left: 10px;
		border-top: none;
		font-size: 14px;
		color: #fff;
		line-height: 36px;
		-webkit-text-size-adjust: none;
		& a,
		& button,
		& select {
			pointer-events: all;
			position: relative;
		}
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.envirabox-title {
		padding: 5px 0;
		margin-left: 10px;
		border-top: none;
		font-size: 14px;
		color: #fff;
		line-height: 36px;
		-webkit-text-size-adjust: none;
		& a,
		& button,
		& select {
			pointer-events: all;
			position: relative;
		}
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
}

.envirabox-container--thumbs .envirabox-caption-wrap,
.envirabox-container--thumbs .envirabox-controls,
.envirabox-container--thumbs .envirabox-slider-wrap {
	right: 0;
	bottom: 90px;
}
.envirabox-thumbs {
	ul li a {
		border: none;
	}

	& ul.mobile li {
		margin: 10px 5px;
	}
}

.envirabox-show-thumbs .envirabox-thumbs {
	display: block;
}
.envirabox-container.envirabox-social.envirabox-show-thumbs .envirabox-thumbs {
	pointer-events: none;
}
.envirabox-container.envirabox-social.envirabox-show-thumbs .envirabox-thumbs > ul > li {
	pointer-events: auto;
}
.envirabox-slide--iframe .envirabox-content {
	padding: 0;
}
//Fix WordPress things
.admin-bar .envirabox-theme-#{$theme_name} {
	.envirabox-toolbar {
		top: 42px;
	}
	.envirabox-caption-wrap {
		top: 32px;
	}
}
@media all and (max-width: 800px) {
	.envirabox-theme-#{$theme_name} {
		.envirabox-container--thumbs .envirabox-controls,
		.envirabox-container--thumbs .envirabox-slider-wrap,
		.envirabox-container--thumbs .envirabox-caption-wrap {
			bottom: 0;
		}
	}
}

@font-face {
	font-family: 'envira-icons';
	src: url('../fonts/envira-icons.eot?gy81ju');
	src: url('../fonts/envira-icons.eot?gy81ju#iefix')
			format('embedded-opentype'),
		url('../fonts/envira-icons.ttf?gy81ju') format('truetype'),
		url('../fonts/envira-icons.woff?gy81ju') format('woff'),
		url('../fonts/envira-icons.svg?gy81ju#envira-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='envira-icons-'],
[class*=' envira-icons-'],
.envirabox-theme-#{$theme_name} .envirabox-close:before,
.envirabox-theme-#{$theme_name} .envirabox-thumbs:before,
.envirabox-theme-#{$theme_name} .envirabox-nav span:before,
.envirabox-theme-#{$theme_name} .envirabox-nav span:after,
.envirabox-theme-#{$theme_name} .envira-close-button:before,
.envirabox-theme-#{$theme_name} .envira-fullscreen-button:before,
.envirabox-theme-#{$theme_name} .envira-zoom-button:before,
.envirabox-theme-#{$theme_name} .envirabox-slideshow-button:before,
.envirabox-theme-#{$theme_name} .envirabox-audio-button:before,
.envirabox-theme-#{$theme_name} .envira-social-network a:after,
.envirabox-theme-#{$theme_name} .envira-download-button:before,
.envirabox-theme-#{$theme_name} .envirabox-button--play:before,
.envirabox-theme-#{$theme_name} .envirabox-button--pause:before,
.envirabox-theme-#{$theme_name} .envira-thumbs-button:before,
.envirabox-theme-#{$theme_name} .envira-printing-button:before,
.envirabox-theme-#{$theme_name} .envira-icons-whatsapp:before,
.envirabox-theme-#{$theme_name} .envira-icons-linkedin:before {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'envira-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: #fff;
	display: block;
}
.envirabox-container.envirabox-theme-#{$theme_name} {
	/* Social */
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior {
	bottom: 15px;
	width: 100%;
	position: absolute;
	z-index: 99;
	pointer-events: none;
	display: table;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons {
	pointer-events: initial;
	display: table;
	margin: 0 auto;
	float: none;
	padding: 5px;
	height: 45px;
	background: rgba(0, 0, 0, 0.35);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	/**
      * Buttons for each Social Network
      */
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons.orientation-horizontal.outside {
	position: relative;
	bottom: 0;
	min-width: inherit;
	max-width: inherit;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons.orientation-vertical.outside {
	position: relative;
	left: 0;
	min-width: inherit;
	max-width: inherit;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	.envira-social-network {
	clear: none;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	.envira-social-network
	a.envira-social-button {
	margin: 5px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	background-image: none;
	background-color: transparent;
	border-radius: inherit;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	text-indent: -9999px;
	display: block;
	outline: none;
	position: relative;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	.envira-social-network
	a.envira-social-button
	span {
	display: none;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	.envira-social-network
	a.envira-social-button:after {
	font-size: 25px;
	text-indent: 0;
	position: absolute;
	top: 0;
	left: 0;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.facebook
	a:after {
	content: '\e905';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.twitter
	a:after {
	content: '\e90d';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.pinterest
	a:after {
	content: '\e90a';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.google
	a:after {
	content: '\e907';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.email
	a:after {
	content: '\e904';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.linkedin
	a:after {
	content: '\eac9';
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.whatsapp
	a.path1:after {
	content: '\e900';
	margin-top: -5px;
	margin-left: -5px;
	font-size: 35px;
}
.envirabox-container.envirabox-theme-#{$theme_name}
	.envira-social-buttons-exterior
	.envira-social-buttons
	div.whatsapp
	a
	.path2:after {
	content: '\e901';
}
.envira-icons-whatsapp .path1:before {
	content: '\e900';
	background: rgba(0, 0, 0, 0.35);
}
.envira-icons-whatsapp .path2:before {
	content: '\e901';
	margin-left: -1em;
	background: rgba(0, 0, 0, 0.35);
}
.envira-icons-thumb:before {
	content: '\e801';
}
.envira-icons-close:before {
	content: '\e903';
}
.envira-icons-email:before {
	content: '\e904';
}
.envira-icons-facebook:before {
	content: '\e905';
}
.envira-icons-fullscreen:before {
	content: '\e906';
}
.envira-icons-google_plus:before {
	content: '\e907';
}
.envira-icons-next:before {
	content: '\e908';
}
.envira-icons-pause:before {
	content: '\e909';
}
.envira-icons-pinterest:before {
	content: '\e90a';
}
.envira-icons-play:before {
	content: '\e90b';
}
.envira-icons-previous:before {
	content: '\e90c';
}
.envira-icons-twitter:before {
	content: '\e90d';
}
.envira-icons-zoom_plus:before {
	content: '\e90e';
}
.envira-icons-zoom:before {
	content: '\e90f';
}
.envira-icons-download:before {
	content: '\e910';
}
.envira-icons-print:before {
	content: '\e911';
}
.envira-icons-linkedin:before {
	content: '\eac9';
}
